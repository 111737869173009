require('./bootstrap');
require('jquery-datetimepicker');

$(document).ready(function() {
    $.datetimepicker.setLocale('de');
    $('#birthdate').datetimepicker({
        timepicker: false,
        format: 'Y-m-d',
        formatDate: 'Y-m-d',
        defaultDate: '+1970/07/01',
        i18n: {
            de: {
                months: [
                    'Januar', 'Februar', 'März', 'April',
                    'Mai', 'Juni', 'Juli', 'August',
                    'September', 'Oktober', 'November', 'Dezember'
                ],
                dayOfWeek: [
                    'So', 'Mo', 'Di', 'Mi',
                    'Do', 'Fr', 'Sa'
                ]
            }
        }
    });
});